.RoomsList {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: 40px;
    flex-direction: column;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  .room {
    width: 400px;
    height: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    border: 1px solid lightgray;
    font-family: Arial, Helvetica, sans-serif;
  
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .room:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
  }
  .room .name {
    flex: 20%;
    border-bottom: 1px solid lightgray;
    background-color: #813afe;
    display: flex;
    place-content: center;
    color: white;
  }
  
  .room .description {
    flex: 60%;
    display: grid;
    place-content: center;
  }
  
  .room .location {
    flex: 20%;
    border-top: 1px solid lightgray;
    display: flex;
    align-items: center;
    padding-left: 15px;
    background-color: #813afe;
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }