.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  width: 100%;
  height: 70px;
  background-color: dodgerblue;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}
.navbar .links {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar .loggedInContainer {
  flex: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbar a {
  margin-left: 20px;
  text-decoration: none;
  color: white;
}

.navbar button {
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
  background-color: white;
}

.navbar h1 {
  text-align: right;
  margin-right: 10px;
  color: white;
  font-weight: lighter;
}
