.btn-primary {
    color: #fff;
    background-color: #813afe !important;
    border-color: #813afe !important;
}



.icon-img{
    width: 70px;
    height: 70px;
    margin-bottom: 2rem;
}